"use client";
import React, { useEffect, useState } from "react";
import SellModalGlobal from "../SellModal";
import { useWindowDimensions } from "@/utils/useWindowDimensions";

export default function SellBtn() {
  // Show / Hide based on scroll direction
  const [logoText, setLogoText] = useState(false);
  let lastScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 100 && currentScrollY > lastScrollY) {
        setLogoText(true);
      } else {
        setLogoText(false);
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const width = useWindowDimensions().width;

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      {isMounted && (
        <>
          {/* DESKTOP */}
          {width && width >= 450 && (
            <div className="">
              <SellModalGlobal
                btn={
                  <div className="p-[3px] relative w-full">
                    <div className="absolute inset-0 bg-gradient-to-r from-primary to-dark-pri rounded-lg" />
                    <div
                      className="flex items-center justify-center hover:brightness-110 px-4 sm:px-4 xl:px-8 py-2.5  bg-dark-default rounded-[6px]  
      relative group transition duration-200 text-base hover:bg-transparent font-500"
                    >
                      Sell
                    </div>
                  </div>
                }
              />
            </div>
          )}
          {/* MOBILE */}
          {width && width < 450 && (
            <div
              className={`${
                logoText
                  ? "flex -translate-x-[8px] transition-transform ease-in-out duration-500" // show
                  : "opacity-0 hidden translate-x-[20px] " // hide
              }`}
            >
              <SellModalGlobal
                btn={
                  <div className="p-[3px] relative w-full">
                    <div className="absolute inset-0 bg-gradient-to-r from-primary to-dark-pri rounded-lg" />
                    <div
                      className="flex items-center justify-center hover:brightness-110 px-4 sm:px-8 py-2.5  bg-dark-default rounded-[6px]  
      relative group transition duration-200 text-base hover:bg-transparent font-500"
                    >
                      Sell
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
