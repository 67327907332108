"use client";
import { useMagic } from "@/context/MagicProvider";
import { logout } from "@/utils/common";
import Tooltip from "@mui/material/Tooltip";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { ImSpinner9 } from "react-icons/im";
import IconPower from "../Icons/IconPower";

export default function Logout() {
  const { magic } = useMagic();

  const router = useRouter();
  const [loggingOut, setLoggingOut] = useState(false);

  const [token, setToken] = useState("");
  const [wallet, setWallet] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        setToken(storedToken);
      }
      const storedWallet = localStorage.getItem("user");
      if (storedWallet) {
        setWallet(storedWallet);
      }
    }
  }, [token]);

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      router.push("/");
      await logout(magic);
    } catch (error) {
    } finally {
      setLoggingOut(false);
    }
  };
  return (
    <Tooltip title="Log out" arrow placement="right">
      <button className="mx-auto " onClick={() => handleLogout()}>
        {loggingOut ? (
          <ImSpinner9 className="text-beige animate-spin w-6 h-6" />
        ) : (
          <IconPower />
        )}
      </button>
    </Tooltip>
  );
}
